// import React from 'react';
// import { Provider } from 'react-redux';
import { createStore , applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const middlewares = [thunk];

// const createStore = (preloadedState) => reduxCreateStore(rootReducer, {}, applyMiddleware(...middlewares));

// export default ({ element }) => (
//   <Provider store={createStore()}>{element}</Provider>
// );

export default preloadedState => {
  return createStore(rootReducer, preloadedState, applyMiddleware(...middlewares));
};