import React from "react"
import { useIntl } from 'gatsby-plugin-intl'
import AnyLink from "../../components/hoc/anyLink/AnyLink"

import FooterStyles from './Footer.module.scss'
import LangSwitcher from "../../components/hoc/langSwithcer/langSwitcher"

const Footer = ({siteTitle}) => {

    const i18n = useIntl().formatMessage

    return (
    <footer className={FooterStyles.footer}>
        <div className="container">
        <div className={["row between-xs stretch-xs", FooterStyles.row].join(' ')}>
            <div className="col col-sm-6 col-md-3 text-center-xs text-left-md bottom-xs flex">
                <ul className="list-unstyled text-white">
                    <li>
                        <AnyLink className={FooterStyles.logo} to="/" style={{margin: 0, marginRight: '0.5rem'}}>
                            {siteTitle}
                        </AnyLink>
                        <LangSwitcher direction="up" invert/>
                    </li>
                    {/* <li>MAMA I/S</li>
                    <li>Blågårdsgade 19, kld</li>
                    <li>2200 København N</li>
                    <li>CVR: 34403139</li> */}
                </ul>   

            </div>
            <div className="col col-sm-6 col-md-3 text-center-xs text-right-md bottom-md flex end-md">
                <ul className="list-unstyled">
                    
                <li>
                        <AnyLink className="" to="/data-policy">
                            { i18n({ id: "pages.data-policy.title" })}
                        </AnyLink>
                    </li>
                <li>
                        <AnyLink className="" to="/privacy-policy">
                            { i18n({ id: "pages.privacy.title" })}
                        </AnyLink>
                    </li>
                    <li>
                        <AnyLink className="" to="/terms-of-service">
                            { i18n({ id: "pages.terms.title" }) }
                        </AnyLink>
                    </li>
                </ul>
            </div>
        </div>
        </div>
    </footer>
    )
}

export default Footer
