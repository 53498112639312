import React, { useState } from "react"
import AnyLink from "../../components/hoc/anyLink/AnyLink"

import NavbarStyles from './Navbar.module.scss'
import LangSwitcher from "../../components/hoc/langSwithcer/langSwitcher"

const Navbar = ({ siteTitle, menuItems, secondaryBtn, primaryBtn, isLoggedIn }) => {
    
    const [navbarExpanded, setNavbarExpanded] = useState(false)

    const menuItemClickHandler = () => {
        console.log('clicked')
        setNavbarExpanded(false)
    }

    return (
        <nav className={NavbarStyles.nav}>
            <div className="container">
                <div className={["row", NavbarStyles.navbarInner].join(' ')}>
                    <div className={["col", NavbarStyles.col].join(' ')}>
                        <AnyLink className={NavbarStyles.logo} to="/">
                            {siteTitle}
                        </AnyLink>
                        <LangSwitcher direction="down" styling={{bottom: '0.1rem'}}/>
                    </div>
                    <div className={["col", NavbarStyles.col, NavbarStyles.menuItems].join(' ')}>
                        <ul className={["list-unstyled flex end-xs", navbarExpanded ? NavbarStyles.visible : null].join(' ')}>
                            <li className={[NavbarStyles.navbarToggle, 'hidden-md hidden-lg hidden-xl'].join(' ')}>
                                <button onClick={() => setNavbarExpanded(!navbarExpanded)}>
                                    <span className={NavbarStyles.toggleIcon}>
                                        <span>Toggle menu</span>
                                    </span>
                                </button>
                            </li>
                            {menuItems.map((item, i) => 
                                <li key={i}>
                                    <AnyLink className={NavbarStyles.pageLinks} 
                                        to={`${item.slug}`} 
                                        activeClassName={NavbarStyles.active} 
                                        partiallyActive={false}
                                        onClick={() => menuItemClickHandler()}>
                                        {item.linkTitle}
                                    </AnyLink>
                                </li>
                            )}
                            
                            <li>
                                <ul className={[!primaryBtn ? NavbarStyles.paddingRight : null, "list-unstyled"].join(' ')}>
                                    {secondaryBtn ? 
                                    <li>
                                        {secondaryBtn && isLoggedIn ?
                                        <button className="text-bold text-blue btn-unstyled btn" onClick={() => secondaryBtn.logoutHandler()}>{secondaryBtn.title}</button>
                                        :
                                        <AnyLink className="text-bold" to={secondaryBtn.link} activeClassName={NavbarStyles.active} partiallyActive={true} onClick={() => menuItemClickHandler()}>{secondaryBtn.title}</AnyLink>
                                        }
                                        </li>
                                        : null}
                                    { primaryBtn ?
                                        <li><AnyLink className="btn btn-secondary" to={primaryBtn.link} onClick={() => menuItemClickHandler()}>{ primaryBtn.title }</AnyLink></li>
                                    : null }
                                </ul>
                            </li>
                        </ul>        
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar