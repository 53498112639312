import React from 'react'
import { Link } from "gatsby-plugin-intl"
import link from 'gatsby-plugin-intl/link';

const queryString = require('query-string');

const AnyLink = ({to, title, className, onClick, children}) => {

    const queryParams = queryString.parse(typeof window !== 'undefined' && window.location.search);
    let linkObject = queryString.parseUrl(to)
    linkObject.query = {...linkObject.query, ...queryParams, }

    const newLink = queryString.stringifyUrl(linkObject)

    return (
        <Link className={ className } to={ newLink } onClick={() => onClick ? onClick() : false}>{ children || title }</Link>
    )
}

export default AnyLink