import {logoutRequest, logoutSuccess, logoutFailure} from '../actions/auth';

function authLogout() {
    return dispatch => {
        console.log('auth Logout called')
        dispatch(logoutRequest());
        
        try {
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            dispatch(logoutSuccess());
        }
        catch(error) {
            dispatch(logoutFailure(error));
        }

    }

}

export default authLogout;