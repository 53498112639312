import {dataFetchRequest, dataFetchSuccess, dataFetchFailure} from '../actions/auth';
const axios = require('axios');

function authFetchData(accessToken) {
    return dispatch => {

        dispatch(dataFetchRequest());

        axios.get(`${process.env.GATSBY_API_ENDPOINT}/api/auth/user/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((res) => {
            if(res.error || !res.data) {
                console.log('authFetchData: throwing error: ', res.error)
                throw(res.error);
            }
            console.log('authFetchData: login successfull: ', res.data)
            dispatch(dataFetchSuccess(res.data));
            return res.data;
        })
        .catch((err) => {
            console.log('authFetchData: catching error:  ', err)
            dispatch(dataFetchFailure(err));
        });
    }
}

export default authFetchData;