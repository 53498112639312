import React from "react"

import ModalStyles from './Modal.module.scss'
import Fade from "../Fade/Fade"

const Modal = ({ children, show, closeHandler, wide}) => (
    <Fade show={show}>
        <div className={ModalStyles.modalWrapper}>
        <div className={["card", ModalStyles.modal, wide ? ModalStyles.modalWide : null].join(' ')}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        { children }
                        <button className={ModalStyles.btn} onClick={() => closeHandler()}><span>Close</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className={ModalStyles.overlay} onClick={() => closeHandler()}></div>
        </div>
    </Fade>
)

export default Modal
