import {tokenRequest, tokenReceived, tokenFailure} from '../actions/auth';
const axios = require('axios');

function authTokenRefresh(token) {
    return dispatch => {
        dispatch(tokenRequest());
        axios.post(`${process.env.GATSBY_API_ENDPOINT}/api/auth/token/refresh/`, {
            refresh: token
        })
        .then((res) => {
            if(res.error) {
                console.log('AuthTokenRefresh: throwing error: ', res.error)
                throw(res.error);
            }
            const async = async () => {
                await localStorage.setItem('access', res.data.access)
                console.log('AuthTokenRefresh: Login successfull: ', res.data.access)
                await dispatch(tokenReceived(res.data.access));
                // return res.data;
            }
            async()
            return res.data
        })
        // .then((res) => {
            
        // })
        .catch(err => {
            console.log('AuthTokenRefresh: catching error:  ', err.data)
            dispatch(tokenFailure(err.data));
        });
    }

}

export default authTokenRefresh;