
// Action creators
export const TOKEN_LOCAL_REQUEST = 'TOKEN_LOCAL_REQUEST';
export const TOKEN_LOCAL_SUCCESS = 'TOKEN_LOCAL_SUCCESS';
export const TOKEN_LOCAL_FAILURE = 'TOKEN_LOCAL_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_RECEIVED = 'TOKEN_RECEIVED';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

export const DATA_FETCH_REQUEST = 'DATA_FETCH_REQUEST';
export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS';
export const DATA_FETCH_FAILURE = 'DATA_FETCH_FAILURE';

export const DATA_SAVE_REQUEST = 'DATA_SAVE_REQUEST';
export const DATA_SAVE_SUCCESS = 'DATA_SAVE_SUCCESS';
export const DATA_SAVE_FAILURE = 'DATA_SAVE_FAILURE';

export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE';

export const NETLIFY_POLLING_REQUEST = 'NETLIFY_POLLING_REQUEST';
export const NETLIFY_POLLING_SUCCESS = 'NETLIFY_POLLING_SUCCESS';
export const NETLIFY_POLLING_FAILURE = 'NETLIFY_POLLING_FAILURE';

export const NETLIFY_DEPLOY_REQUEST = 'NETLIFY_DEPLOY_REQUEST';
export const NETLIFY_DEPLOY_SUCCESS = 'NETLIFY_DEPLOY_SUCCESS';
export const NETLIFY_DEPLOY_FAILURE = 'NETLIFY_DEPLOY_FAILURE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const EMAIL_PASSWORD_RESET_REQUEST = 'EMAIL_PASSWORD_RESET_REQUEST';
export const EMAIL_PASSWORD_RESET_SUCCESS = 'EMAIL_PASSWORD_RESET_SUCCESS';
export const EMAIL_PASSWORD_RESET_FAILURE = 'EMAIL_PASSWORD_RESET_FAILURE';



// Login actions
export const loginRequest = () => ({
    type: LOGIN_REQUEST
});

export const loginSuccess = (data) => {
    return {
        type: LOGIN_SUCCESS,
        data: data
    }
};

export const loginFailure = (error) => {
    return {
        type: LOGIN_FAILURE,
        error: error
    }
};

// Token refresh actions
export const tokenRequest = () => {
    return {
        type: TOKEN_REQUEST
    }
};

export const tokenReceived = (access, refresh) => {
    return {
        type: TOKEN_RECEIVED,
        access: access,
        refresh: refresh
    }
};

export const tokenFailure = (error) => {
    return {
        type: TOKEN_FAILURE,
        error: error
    }
};


// Token local check actions
export const tokenLocalRequest = () => {
    return {
        type: TOKEN_LOCAL_REQUEST,
    }
};

export const tokenLocalSuccess = (tokens) => {
    return {
        type: TOKEN_LOCAL_SUCCESS,
        tokens: tokens
    }
};

export const tokenLocalFailure = (error) => {
    return {
        type: TOKEN_LOCAL_FAILURE,
        error: error
    }
};


// Data fetch actions
export const dataFetchRequest = () => ({
    type: DATA_FETCH_REQUEST,
});

export const dataFetchSuccess = (data) => {
    return {
        type: DATA_FETCH_SUCCESS,
        data: data
    }
};

export const dataFetchFailure = (error) => {
    return {
        type: DATA_FETCH_FAILURE,
        error: error
    }
};

// Data save actions
export const dataSaveRequest = () => ({
    type: DATA_SAVE_REQUEST,
});

export const dataSaveSuccess = (data) => {
    return {
        type: DATA_SAVE_SUCCESS,
        data: data
    }
};

export const dataSaveFailure = (error) => {
    return {
        type: DATA_SAVE_FAILURE,
        error: error
    }
};

// Data save actions
export const registerRequest = () => ({
    type: REGISTER_REQUEST,
});

export const registerSuccess = (data) => {
    return {
        type: REGISTER_SUCCESS,
        data: data
    }
};

export const registerFailure = (error) => {
    return {
        type: REGISTER_FAILURE,
        error: error
    }
};

export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
};

export const logoutFailure = (error) => {
    return {
        type: LOGOUT_FAILURE,
        error: error
    }
};

// Create Site on Netlify
export const createSiteRequest = () => ({
    type: CREATE_SITE_REQUEST,
});

export const createSiteSuccess = (data) => {
    return {
        type: CREATE_SITE_SUCCESS,
        data: data
    }
};

export const createSiteFailure = (error) => {
    return {
        type: CREATE_SITE_FAILURE,
        error: error
    }
};


// Polling Site on Netlify
export const netlifyPollingRequest = () => ({
    type: NETLIFY_POLLING_REQUEST,
});

export const netlifyPollingSuccess = (data) => {
    return {
        type: NETLIFY_POLLING_SUCCESS,
        data: data
    }
};

export const netlifyPollingFailure = (error) => {
    return {
        type: NETLIFY_POLLING_FAILURE,
        error: error
    }
};

// Deploying Site on Netlify
export const netlifyDeployRequest = () => ({
    type: NETLIFY_DEPLOY_REQUEST,
});

export const netlifyDeploySuccess = (data) => {
    return {
        type: NETLIFY_DEPLOY_SUCCESS,
        data: data
    }
};

export const netlifyDeployFailure = (error) => {
    return {
        type: NETLIFY_DEPLOY_FAILURE,
        error: error
    }
};

// Get email link to change password
export const emailPasswordResetRequest = () => ({
    type: EMAIL_PASSWORD_RESET_REQUEST,
});

export const emailPasswordResetSuccess = () => {
    return {
        type: EMAIL_PASSWORD_RESET_SUCCESS,
    }
};

export const emailPasswordResetFailure = (error) => {
    return {
        type: EMAIL_PASSWORD_RESET_FAILURE,
        error: error
    }
};

// Change password
export const passwordResetRequest = () => ({
    type: PASSWORD_RESET_REQUEST,
});

export const passwordResetSuccess = () => {
    return {
        type: PASSWORD_RESET_SUCCESS,
    }
};

export const passwordResetFailure = (error) => {
    return {
        type: PASSWORD_RESET_FAILURE,
        error: error
    }
};
