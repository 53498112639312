import React, { useEffect, useState } from "react";

import FadeStyles from "./Fade.module.scss"

const Fade = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <div
        className={[FadeStyles.fadeWrapper, show ? FadeStyles.fadeIn : FadeStyles.fadeOut].join(' ')}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default Fade;