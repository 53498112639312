import {tokenLocalRequest, tokenLocalSuccess, tokenLocalFailure} from '../actions/auth';


function authTokenLocal(tokens) {
    console.log('about to dispatch')
    return dispatch => {
        console.log('auth Logout called')
        console.log('dispatching')
        dispatch(tokenLocalRequest());

        try {
            const async = async () => {
                
                console.log('step 2', tokens)
                    
                await localStorage.setItem('access', tokens.access)
                await localStorage.setItem('refresh', tokens.refresh)
                    
                await dispatch(tokenLocalSuccess(tokens));
            }
            async()
        }
        catch(error) {
            dispatch(tokenLocalFailure(error));
            console.log('Error: ', error.message)
        }

    }


    
    console.log('autTokenLocal')
    return async dispatch => {
        


        // new Promise((resolve, reject) => {
        //     console.log('dispatching token local request')
        //     dispatch(tokenLocalRequest());
            
        //     resolve();
        // })
        // .then(() => {
        //     // Check local storage for tokens and set state with their value
        //     console.log('step 2', tokens)
        //     const async = async () => {
        //         await localStorage.setItem('access', tokens.access)
        //         await localStorage.setItem('refresh', tokens.refresh)
        //     }
        //     async()
        //     dispatch(tokenLocalSuccess(tokens));
        //     return tokens
            
        // })
        // .catch((err) => {
        //     dispatch(tokenLocalFailure(err));
        //     console.log('Error: ', err.message)
        // })
        
    }

}

export default authTokenLocal;