import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchAction from '../state/async/authFetchData';
import logoutAction from '../state/async/authLogout';
import localTokenAction from '../state/async/authTokenLocal';
import {isLoggedIn, getData, getState, getErrors, getAccessToken, getRefreshToken, refreshToken, isAccessTokenExpired, isRefreshTokenExpired, isAuthenticated, loginPending} from '../state/reducers/auth';
import authTokenRefresh from '../state/async/authTokenRefresh';

import "./sass/app.scss"
import LayoutStyles from "./layout.module.scss"
import Navbar from "./Navbar/Navbar"
import ErrorBoundary from './SentryWrapper'
import Footer from "./Footer/Footer"
import Modal from "../components/hoc/Modal/Modal"
import slugify from "../components/hoc/slugify/Slugify"
import { Helmet } from "react-helmet"
import AnyLink from "../components/hoc/anyLink/AnyLink";

const Layout = ({ children, refresh, access, accessExpired, authTokenRefresh, fetchAction, isLoggedIn, logoutAction, location, isAuthenticated, loginPending, error, localTokenAction}) => {
  const i18n = useIntl().formatMessage
  const isDashboard = location.pathname.includes('app')

  const accessTokenLocal = typeof localStorage !== 'undefined' ? localStorage.getItem('access') : null
  const refreshTokenLocal = typeof localStorage !== 'undefined' ? localStorage.getItem('refresh') : null
  

  const [showError, setShowError] = useState(error ? true : false)

  let tokens = null

  

  useEffect(() => {
    tokens = accessTokenLocal && refreshTokenLocal ? {
      access: accessTokenLocal,
      refresh: refreshTokenLocal
    } : null

  })

  useEffect(() => {
    const loginAsync = async () => {
    if(isAuthenticated) {
      console.log('Refresh active, user authenticated: ')
      if(!accessExpired && access && access.token) {
        console.log('Access active ')
        await fetchAction(access.token)
      } else if(refresh && refresh.token) {
        console.log('Access not active, but refresh is active ')
        await authTokenRefresh(refresh.token)
      }
    } else if(tokens) {
      console.log('about to dispatch local storage tokens')
      localTokenAction(tokens)
    } else {
      console.log('Refresh not active, user not authenticated')
    }
  }
  loginAsync()
  }, [isAuthenticated, accessExpired])

  useEffect(() => {
    if(error && slugify(error) === "error-request-failed-with-status-code-401") {
      logoutAction()
    } else if(error) {
      setShowError(true)
    }
  }, [error])

  const logoutHandler = () => {
    console.log('logout handler clicked')
    logoutAction()
  }

  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ErrorBoundary>
        <Helmet>
          <script src="https://js.stripe.com/v3/"></script>
        </Helmet>
        <Navbar 
          siteTitle={data.site.siteMetadata.title} 
          menuItems={
            isDashboard ?
              [{linkTitle: i18n({ id: "menu.editor"}), slug: '/app'}, {linkTitle: i18n({ id: "menu.settings"}), slug: '/app/settings'}, {linkTitle: i18n({ id: "menu.account"}), slug: '/app/account'}, {linkTitle: 'Support', slug: '/support'}]
            :
              [
                {linkTitle: i18n({ id: "menu.product"}), slug: '/product'}, 
                {linkTitle: i18n({ id: "menu.price"}), slug: '/pricing'},
                {linkTitle: i18n({ id: "menu.about"}), slug: '/about'},
                {linkTitle: 'Support', slug: '/support'}
              ]
          } 
          // secondaryBtn={{title: i18n({ id: isLoggedIn ? "general.logout" : "general.login" }), link: '/login', logoutHandler: logoutHandler}} 
          primaryBtn={
            !isDashboard ?
              {title: i18n({ id: isLoggedIn ? "general.dashboard" : "general.register"}), link: isLoggedIn ? '/app' : '/register'}
            : null} 
            secondaryBtn={
            {
              title: i18n({ id: isLoggedIn ? "general.logout" : "general.login" }), 
              link: '/login', logoutHandler: logoutHandler
            }
          } 
          isLoggedIn={isLoggedIn}/>
        <main className={LayoutStyles.pageContent}>
          <Modal 
            show={showError ? true : false} 
            closeHandler={() => setShowError(false)}>
              <div className="text-center">
                {error ? <h3 className="text-center">{i18n({ id: `general.errors.${slugify(error)}.title`}) || i18n({ id: `general.errors.error_occured.title`})}</h3> : null }
                {error ? <p className="text-center">{ i18n({ id: `general.errors.${slugify(error)}.text`}) || error }</p> : null }
                {error ? <p className="text-center">{ i18n({ id: `general.errors.error_occured.support`}) || error }</p> : null }
                <AnyLink className="btn btn-primary" to="/" onClick={() => setShowError(false)}>Gå til forsiden</AnyLink>
              </div>
          </Modal>
          {children}
        </main>
        
        <Footer siteTitle={data.site.siteMetadata.title} />
          {/* <div className={LayoutStyles.cols}>
            <div className="container">
              <div className="row">
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
                <div className="col col-xs-1"><div className="col-border"></div></div>
              </div>
            </div>
          </div> */}
          </ErrorBoundary>
    )}
  />
)
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = state => ({
  error: getErrors(state.auth),
  data: getData(state.auth),
  isLoggedIn: isLoggedIn(state.auth),
  access: getAccessToken(state.auth),
  refresh: getRefreshToken(state.auth),
  accessExpired: isAccessTokenExpired(state.auth),
  refreshExpired: isRefreshTokenExpired(state.auth),
  isAuthenticated: isAuthenticated(state.auth),
  loginPending: loginPending(state.auth)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAction: fetchAction,
  authTokenRefresh: authTokenRefresh,
  logoutAction: logoutAction,
  localTokenAction: localTokenAction,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
