import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, TOKEN_REQUEST, TOKEN_RECEIVED, TOKEN_FAILURE, TOKEN_LOCAL_REQUEST, TOKEN_LOCAL_SUCCESS, TOKEN_LOCAL_FAILURE, DATA_FETCH_REQUEST, DATA_FETCH_SUCCESS, DATA_FETCH_FAILURE, DATA_SAVE_REQUEST, DATA_SAVE_SUCCESS, DATA_SAVE_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, CREATE_SITE_REQUEST, CREATE_SITE_SUCCESS, CREATE_SITE_FAILURE, NETLIFY_POLLING_REQUEST, NETLIFY_POLLING_SUCCESS, NETLIFY_POLLING_FAILURE, NETLIFY_DEPLOY_REQUEST, NETLIFY_DEPLOY_SUCCESS, NETLIFY_DEPLOY_FAILURE, EMAIL_PASSWORD_RESET_REQUEST, EMAIL_PASSWORD_RESET_SUCCESS, EMAIL_PASSWORD_RESET_FAILURE, PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE} from '../actions/auth';
import jwtDecode from 'jwt-decode'

const initAccessToken = typeof window !== 'undefined' ? localStorage.getItem('access') : null
const initRefreshToken = typeof window !== 'undefined' ? localStorage.getItem('refresh') : null

const initialState = {
    loginPending: false,
    tokenPending: false,
    dataFetchPending: false,
    dataSaved: true,
    changesPublished: null,
    isLoggedIn: false,
    buildStatus: 'retrieving',
    access: {
        token: initAccessToken,
        ...(initAccessToken ? jwtDecode(initAccessToken) : null)
    },
    refresh: {
        token: initRefreshToken,
        ...(initRefreshToken ? jwtDecode(initRefreshToken) : null)
    },
    data: null,
    error: null,
    netlifySiteId: null,
    emailPasswordSend: false,
    passwordReset: false
};
  
// Reducers
export default (state = initialState, action) => {
    switch(action.type) {

        // CHECH IF TOKENS EXISTS IN LOCAL STORAGE - Layout component on mount.
        case TOKEN_LOCAL_REQUEST:
            // Check local storage for tokens
            return {
                ...state,
                loginPending: true,
                error: null
            }
        case TOKEN_LOCAL_SUCCESS:
            // Set state with tokens and dispatch REQUEST_DATA action if access is active or if only refresh token is active dispatch TOKEN_REQUEST action..
            return {
                ...state,
                loginPending: true,
                access: {
                    token: action.tokens.access,
                    ...jwtDecode(action.tokens.access)
                },
                refresh: {
                    token: action.tokens.refresh,
                    ...jwtDecode(action.tokens.refresh)
                },
                error: null
            }
        case TOKEN_LOCAL_FAILURE:
            // Set isLoggedIn to false, redirect protected routes to login, 
            return {
                ...state,
                loginPending: false,
                error: action.error.message
            }
        
        // IF NO TOKENS OR TOKENS EXPIRED  - login component on user action.
        case LOGIN_REQUEST: 
            // Start login if no active tokens exists.
            return {
                ...state,
                loginPending: true,
                formError: null
            }
        case LOGIN_SUCCESS:
            // Set state with data and new tokens and add to local storage
            return {
                ...state,
                loginPending: false,
                isLoggedIn: true,
                // data: action.data,
                access: {
                    token: action.data.access,
                    ...jwtDecode(action.data.access)
                },
                refresh: {
                    token: action.data.refresh,
                    ...jwtDecode(action.data.refresh)
                },
                formError: null
            }
        case LOGIN_FAILURE:
            // Set state with error that displays on the login form
            return {
                ...state,
                isLoggedIn: false,
                loginPending: false,
                formError: action.error
            }
        
        
            // IF NO TOKENS OR TOKENS EXPIRED  - login component on user action.
        case LOGOUT_REQUEST: 
            // Start login if no active tokens exists.
            return {
                ...state,
                logoutPending: true,
                error: null
            }
        case LOGOUT_SUCCESS:
            // Set state with data and new tokens and add to local storage
            return {
                ...state,
                logoutPending: false,
                isLoggedIn: false,
                // data: action.data,
                access: {
                    token: null
                },
                refresh: {
                    token: null
                },
                data: null,
                error: null
            }
        case LOGOUT_FAILURE:
            // Set state with error that displays on the login form
            return {
                ...state,
                isLoggedIn: false,
                logoutPending: false,
                error: action.error
            }
            
        case REGISTER_REQUEST:
            // Make api request. Show loading spinner.
            return {
                ...state,
                registerPending: true,
                registerSuccess: false,
                formError: null
            }
        case REGISTER_SUCCESS:
            // Set state and redirect to protected view with fetched data 
            return {
                ...state,
                registerPending: false,
                registerSuccess: true,
                isLoggedIn: false,
                data: action.data,
                formError: null
            }
        case REGISTER_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                isLoggedIn: false,
                registerSuccess: false,
                loginPending: false,
                dataFetchPending: false,
                formError: action.error
            }
    

        // IF ACCESS TOKEN EXPIRED - dispatched from TOKEN_LOCAL_FAILURE
        case TOKEN_REQUEST:
            // Request refresh of access token from server.
            return {
                ...state,
                tokenPending: true,
            }
        case TOKEN_RECEIVED:
            // Set state and localStorage with tokens. Dispatch DATA_FETCH_REQUEST
            return {
                ...state,
                tokenPending: false,
                access: {
                    token: action.access,
                    ...jwtDecode(action.access)
                } 
            }
        case TOKEN_FAILURE:
            return {
                ...state,
                tokenPending: false,
                access: null,
                refresh: null,
                error: action.error
            }

        // FETCH DATA FROM SERVER - dispatched from TOKEN_LOCAL_SUCCESS or TOKEN_RECEIVED
        case DATA_FETCH_REQUEST:
            // Make api request. Show loading spinner.
            return {
                ...state,
                dataFetchPending: true,
                error: null
            }
        case DATA_FETCH_SUCCESS:
            console.log('LOGIN SUCECESS', action.data)
            
            // Set state and redirect to protected view with fetched data 
            return {
                ...state,
                loginPending: false,
                isLoggedIn: true,
                netlifySiteId: action.data.profile.websites[0].netlify_site_id ? action.data.profile.websites[0].netlify_site_id : state.netlifySiteId,
                dataSaved: true,
                changesPublished: action.data.profile.websites[0].changes_published,
                data: action.data,
                dataFetchPending: false,
                buildStatus: action.data.profile.websites[0].netlify_site_live === false ? 'not_live' : 'retrieving',
                error: null
            }
        case DATA_FETCH_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                isLoggedIn: false,
                loginPending: false,
                dataFetchPending: false,
                error: action.error
            }
            
        // FETCH DATA FROM SERVER - dispatched from TOKEN_LOCAL_SUCCESS or TOKEN_RECEIVED
        case DATA_SAVE_REQUEST:
            
            // Make api request. Show loading spinner.
            return {
                ...state,
                dataSavePending: true,
                dataSaved: false,
                dataSaveSuccess: false,
                error: null
            }
        case DATA_SAVE_SUCCESS:
            // Set state and redirect to protected view with fetched data 
            const isChangesPublished = action.data.changes_published !== 'undefined' ? {changesPublished: action.data.changes_published} : {}
            return {
                ...state,
                data: {
                    ...state.data,
                    profile: state.data && {
                        ...state.data.profile,
                        websites: [action.data]
                    }
                },
                dataSaved: true,
                dataSaveSuccess: true,
                ...isChangesPublished,
                dataSavePending: false,
                error: null
            }
        case DATA_SAVE_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            // const isChangesPublished = action.data.changesPublished !== 'undefined' ? {changesPublished: action.data.changesPublished} : {}
            return {
                ...state,
                dataSaved: false,
                dataSavePending: false,
                dataSaveSuccess: false,
                // ...isChangesPublished,
                error: action.error
            }
        case CREATE_SITE_REQUEST:
            // Make api request. Show loading spinner.
            return {
                ...state,
                deployPending: true,
                deployed: false,
                error: null
            }
        case CREATE_SITE_SUCCESS:
            // Set state and redirect to protected view with fetched data 
            return {
                ...state,
                deployPending: true,
                deployed: false,
                netlifySiteId: action.data,
                buildStatus: 'retrieving',
                error: null
            }
        case CREATE_SITE_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                deployPending: false,
                deployed: false,
                error: action.error
            }
        case NETLIFY_POLLING_REQUEST:
            // Make api request. Show loading spinner.
            return {
                ...state,
                error: null
            }
        case NETLIFY_POLLING_SUCCESS:
            // Set state and redirect to protected view with fetched data 
            return {
                ...state,
                buildStatus: action.data.state,
                lastPublished: new Date(action.data.updated_at).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
                error: null
            }
        case NETLIFY_POLLING_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                buildStatus: action.error === 404 ? 'not_created' : 'not_retrieved',
                // error: action.error
            }
        case NETLIFY_DEPLOY_REQUEST:
            // Make api request. Show loading spinner.
            return {
                ...state,
                buildStatus: 'building',
                error: null
            }
        case NETLIFY_DEPLOY_SUCCESS:
            // Set state and redirect to protected view with fetched data 
            return {
                ...state,
                buildStatus: 'building',
                lastPublished: new Date(action.data.created_at).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
                error: null,
                changesPublished: true
            }
        case NETLIFY_DEPLOY_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                buildStatus: action.error === 404 ? 'not_created' : 'not_retrieved',
                // error: action.error
            }
        case EMAIL_PASSWORD_RESET_REQUEST:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                emailPasswordSend: false,
                emailPasswordSending: true,
                formError: null
            }
        case EMAIL_PASSWORD_RESET_SUCCESS:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                emailPasswordSend: true,
                emailPasswordSending: false,
                formError: null
            }
        case EMAIL_PASSWORD_RESET_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                emailPasswordSend: false,
                emailPasswordSending: false,
                formError: action.error
            }
        case PASSWORD_RESET_REQUEST:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                passwordReset: false,
                passwordResetting: true,
                formError: null
            }
        case PASSWORD_RESET_SUCCESS:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                passwordReset: true,
                passwordResetting: false,
                formError: null
            }
        case PASSWORD_RESET_FAILURE:
            // Show load error and link to login page or alternatively retry button or both.
            return {
                ...state,
                passwordReset: false,
                passwordResetting: false,
                formError: action.error
            }
        default: 
            return state;
  }
}

// State access methods

export function accessToken(state) {
  if (state.access && state.access ==! undefined) {
      return  state.access.token
  }
}
  
export function refreshToken(state) {
  if (state.refresh && state.refresh ==! undefined) {
      return  state.refresh.token
  }
}
  
export function isAccessTokenExpired(state) {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - (new Date()).getTime() < 5000
  }
  return true
}
export function isRefreshTokenExpired(state) {
  if (state.refresh && state.refresh.exp) {
    return 1000 * state.refresh.exp - (new Date()).getTime() < 5000
  }
  return true
}
export function isAuthenticated(state) {
  return !isRefreshTokenExpired(state)
}
export function errors(state) {
  return state.errors
}
export function fetchedData(state) {
    return state.data
}

export const isLoggedIn = state => state.isLoggedIn;
export const loginPending = state => state.loginPending;
export const getData = state => state.data;
export const getAccessToken = state => state.access;
export const getRefreshToken = state => state.refresh;
export const getErrors = state => state.error;
export const formError = state => state.formError;
export const dataSavePending = state => state.dataSavePending;
export const dataSaved = state => state.dataSaved;
export const dataSaveSuccess = state => state.dataSaveSuccess;
export const deployPending = state => state.deployPending;
export const deployed = state => state.deployed
export const netlifySiteId = state => state.netlifySiteId
export const buildStatus = state => state.buildStatus
export const lastPublished = state => state.lastPublished
export const changesPublished = state => state.changesPublished
export const emailPasswordSend = state => state.emailPasswordSend
export const emailPasswordSending = state => state.emailPasswordSending
export const passwordReset = state => state.passwordReset
export const passwordResetting = state => state.passwordResetting
export const registerSuccess = state => state.registerSuccess
export const registerPending = state => state.registerPending


